import { Controller } from 'stimulus';
import linkifyElement from 'linkifyjs/element';

export default class extends Controller {
  static targets = ['message'];

  connect() {
    linkifyElement(this.messageTarget, { target: { url: '_blank' } });
  }
}
