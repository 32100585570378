import { Controller } from 'stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {
  static targets = [
    'form',
    'submitButton',
    'fileField',
    'dropArea',
    'fileCounter',
    'processingMessage',
  ];

  highlight() {
    this.dropAreaTarget.classList.add('is-active');
  }

  unhighlight() {
    this.dropAreaTarget.classList.remove('is-active');
  }

  getSignedUrl(file) {
    return post(`/guests/${gon.current_guest_id}/signed_url`, {
      body: { filename: file.name, mime_type: file.type },
    });
  }

  uploadFile(file) {
    return new Promise((resolve, reject) => {
      this.getSignedUrl(file)
        .then((response) => response.json)
        .then((json) => {
          const data = new FormData();
          Object.keys(json.data).forEach((key) => {
            data.append(key, json.data[key]);
          });
          data.append('file', file);

          fetch(json.url, {
            method: 'POST',
            body: data,
            mode: 'cors',
          })
            .then((s3Response) => s3Response.text())
            .then((xml) => {
              const parser = new DOMParser();
              const xmlDoc = parser.parseFromString(xml, 'application/xml');
              const location = xmlDoc.querySelector('Location').innerHTML;
              resolve(location);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  submit(event) {
    event.preventDefault();

    this.formTarget.style.display = 'none';
    this.processingMessageTarget.style.display = 'block';

    const uploadPromises = [];
    const files = this.fileFieldTarget.files;
    const photoUrls = [];
    for (let i = 0; i < files.length; i += 1) {
      uploadPromises.push(
        this.uploadFile(files[i]).then(function (url) {
          photoUrls.push(url);
        }),
      );
    }

    const form = this.formTarget;
    Promise.all(uploadPromises).then(function () {
      post(form.getAttribute('action'), {
        body: { photo_urls: photoUrls },
        contentType: 'application/json',
      }).then(() => document.querySelector('.modal-close-button').click());
    });

    return false;
  }

  filesDropped(event) {
    event.preventDefault();
    const files = this.fileFieldTarget.files;
    if (files.length > 0) {
      this.fileCounterTarget.innerHTML = `${files.length} files selected`;
      this.submitButtonTarget.style.display = 'block';
    }
  }
}
